class AjaxForm {
	constructor(form) {
		this.form = form;
		this.submitting = false;
		this.listen();
	}

	listen() {
		this.form.addEventListener("submit", event => {
			if (this.submitting) {
				return;
			}

			event.preventDefault();
			this.submit();
		});
	}

	submit() {
		const captcha = this.form.querySelector('[name="h-captcha-response"]');

		if (captcha && captcha.value.trim().length === 0) {
			alert(captcha.parentNode.dataset.captchaMessage);
			return;
		}

		this.submitting = true;
		const request = new XMLHttpRequest();
		let payload = new FormData(this.form);
		payload.append("is-ajax", "1");
		this.form.classList.add("is-loading");

		request.onreadystatechange = () => {
			if (request.status === 200 && request.readyState === 4) {
				const template = document.createElement('template');
				template.innerHTML = request.responseText;
				const newForm = template.content.firstChild;

				this.form.parentNode.replaceChild(newForm, this.form);

				if (newForm.tagName.toLowerCase() === "form") {
					this.form = newForm;
					this.listen();
				}

				const re = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
				let match;

				while ((match = re.exec(newForm.outerHTML))) {
					eval(match[1]);
				}
			}
		};

		request.open(this.form.method.toUpperCase(), this.form.action, true);
		request.send(payload);
	}
}

const forms = [...document.querySelectorAll("form.ajax-form")];

forms.forEach(form => {
	new AjaxForm(form);
})
